// components/HreflangLinks.tsx
import React, { useEffect, useMemo, useState } from 'react'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { transformLocale } from '../utils/utils'

interface HreflangLinksProps {
  locales: string[]
}

const HreflangLinks: React.FC<HreflangLinksProps> = ({ locales }) => {
  const router = useRouter()
  const { asPath, defaultLocale } = router

  const [currentOrigin, setCurrentOrigin] = useState<string | null>(null)
  const localeLangs = useMemo(
    () => locales.map((loc) => transformLocale(loc, '-')),
    [locales]
  )
  const defaultLang = useMemo(
    () => localeLangs.find((lang) => lang.includes(defaultLocale)),
    [defaultLocale, localeLangs]
  )

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setCurrentOrigin(window.location.origin)
    }
  }, [])

  if (!currentOrigin || locales.length === 0 || !defaultLang) return null

  const hreflangLinks = localeLangs.map((loc) => {
    const isDefault = loc === defaultLang
    return {
      href: isDefault
        ? `${currentOrigin}${asPath}`
        : `${currentOrigin}/${loc.split('-')[0]}${asPath}`,
      hreflang: isDefault ? 'x-default' : loc,
    }
  })

  return (
    <Head>
      {hreflangLinks.map((link, index) => (
        <link
          key={index}
          rel="alternate"
          href={link.href}
          hrefLang={link.hreflang}
        />
      ))}
    </Head>
  )
}

export default HreflangLinks
